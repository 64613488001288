import React from 'react';
import { FaBitcoin, FaPhone } from 'react-icons/fa';

const Contact: React.FC = () => {
  return (
    <div className=''>
      <div className='flex flex-col lg:flex-row  md:space-x-10  my-10 mx-20 p-10 pb-20 bg-highlight'>
        <div className='flex-1 flex flex-col space-y-10'>
          <h1 className='text-4xl text-center mt-8 text-text'>Contact Us</h1>
          <p className='text-md w-2/3 text-center text-text mx-auto'>
            If you have any questions, please contact us at the following
            details.
          </p>
          <div className='flex flex-col space-y-4'>
            <div className='flex items-center'>
              {/* <Fa className='text-blue-500 mr-2' /> */}
              <p>Kevin Bell</p>
            </div>
            <div className='flex items-center'>
              <FaBitcoin className='text-blue-500 mr-2' />
              <p>Cadena Bitcoin</p>
            </div>
            {/* <div className='flex items-center'>
              <FaMapMarkerAlt className='text-blue-500 mr-2' />
              <p>400 metró oeste de Hotel Tramonto</p>
            </div>
            <div className='flex items-center'>
              <FaMapMarkerAlt className='text-blue-500 mr-2' />
              <p>Playa Hermosa, Punterenas, Costa Rica</p>
            </div> */}
            <div className='flex items-center'>
              <FaPhone className='text-blue-500 mr-2' />
              <p>416-769-1440</p>
            </div>
            <div className='flex items-center'>
              <FaPhone className='text-blue-500 mr-2' />
              <p>506-8623-5890</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
