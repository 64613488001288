let authToken: string | null = null;

export const saveToken = (token: string) => {
  authToken = token;
  localStorage.setItem('authToken', token);
};

export const getToken = (): string | null => {
  if (!authToken) {
    authToken = localStorage.getItem('authToken');
  }
  return authToken;
};

export const removeToken = () => {
  authToken = null;
  localStorage.removeItem('authToken');
};