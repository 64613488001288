import React, { useState, useEffect } from 'react';
import Dlc from './Dlc';
import { DlcData } from '../types/dlcFace';
import { fetchDlcs } from '../utils/dlc';

const UserDlcsList: React.FC = () => {
  const [dlcs, setDlcs] = useState<DlcData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchDlcs(setDlcs, setLoading, setError);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // Egyesítsd a listákat és rendezd őket dlc_id szerint
  const allDlcs = dlcs
    .map((dlc) => ({
      ...dlc,
      statusLabel:
        dlc.status === 'offer_p'
          ? 'Offer'
          : dlc.status === 'accepted_p'
          ? 'Accepted'
          : 'Signed',
      statusStyle:
        dlc.status === 'offer_p'
          ? 'py-1 px-2 bg-background'
          : dlc.status === 'accepted_p'
          ? 'py-1 px-2 bg-primary'
          : 'py-1 px-2 bg-accent',
    }))
    .sort((a, b) => a.dlc_id.localeCompare(b.dlc_id));

  return (
    <div className='w-3/4 mx-auto mt-5 mb-10'>
      <h2 className='text-text bg-primary p-4 py-2 text-2xl'>User DLCs</h2>
      <div className='grid grid-cols-1 justify-center'>
        {dlcs.length > 0 && (
          <div className='grid grid-cols-5 text-center gap-4 px-4 py-2 bg-background'>
            <div className={`text-text text-lg`}>DLC ID</div>
            <div className={`text-text text-lg`}>Oracle</div>
            <div className={`text-text text-lg`}>Interest</div>
            <div className={`text-text text-lg`}>Status</div>
            <div className={`text-text text-lg`}>View</div>
          </div>
        )}
        <div className='dlc_list bg-highlight'>
          {allDlcs.map((dlc, index) => (
            <Dlc
              key={index}
              dlcs={[dlc]}
              textColor='text-text'
              statusLabel={dlc.statusLabel}
              statusStyle={dlc.statusStyle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserDlcsList;
