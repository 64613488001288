import React, { useState } from 'react';
import Button from './common/Button';
import Input from './common/Input';
import usePostAuthLogic from '../hooks/usePostAuthLogic';
import { usePostOffer } from '../hooks/usePostOffer';
import useOfferListLogic from '../hooks/useOfferListLogic';

interface OfferPostFormProps {
  role: 'lend' | 'borrow';
  onClose: () => void; // Add onClose prop to handle closing the form
}

const OfferPostForm: React.FC<OfferPostFormProps> = ({ role, onClose }) => {
  const [interest, setInterest] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { isLoggedIn, handleCancel } = usePostAuthLogic();
  const { postOfferWithAuth, loading, error } = usePostOffer();
  const { refreshOffers } = useOfferListLogic();

  const handleSubmit = async () => {
    const offerData = {
      product_id: 'LendBorrowBTCUSD',
      tmp_cntr_id: '',
      ini_role: role,
      interest: parseFloat(interest),
    };

    try {
      const response = await postOfferWithAuth(offerData);
      setSuccessMessage(response.message);
      refreshOffers(); // Refresh the offer list after successful
      onClose(); // Close the form after successful submission
    } catch (error) {
      setSuccessMessage(null);
    }
  };

  const handleFormCancel = () => {
    handleCancel();
    onClose(); // Close the form on cancel
  };

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
      <div className='relative bg-white rounded-lg p-8 shadow-lg w-3/4 md:w-1/2 text-left'>
        {/* <Button
          icon={<span>&times;</span>}
          onClick={onClose}
          className='absolute top-2 right-2 text-white bg-transparent hover:bg-transparent border-none text-3xl hover:bolder focus:bolder hover:text-3xl focus:text-3xl'
        /> */}
        <h2 className='text-2xl font-bold mb-4 text-text'>Post Offer</h2>
        {isLoggedIn && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Input
              type='text'
              name='interest'
              value={interest}
              onChange={(e) => setInterest(e.target.value)}
              placeholder='Enter interest'
              className='w-full p-2 border border-gray-300 text-black rounded mb-2'
            />
            <div className='flex space-x-4'>
              <Button
                label={loading ? 'Submitting...' : 'POST'}
                onClick={handleSubmit}
                className='bg-button bg-button-hover w-full'
                disabled={loading}
              />
              <Button
                label='CANCEL'
                onClick={handleFormCancel}
                className='bg-button bg-button-hover w-full'
              />
            </div>
            {error && <p className='text-red-500'>{error}</p>}
            {successMessage && (
              <p className='text-green-500'>{successMessage}</p>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default OfferPostForm;
