import React from 'react';
import Button from './common/Button';
import usePostAuthLogic from '../hooks/usePostAuthLogic';
import OfferDetails from './OfferDetails';
import useOfferListLogic from '../hooks/useOfferListLogic';
import useOfferLogic from '../hooks/useOfferLogic';
import { FaHammer } from 'react-icons/fa';
import '../styles/best_deal.scss';

interface BidButtonProps {
  onClick: () => void;
}

const BidButton: React.FC<BidButtonProps> = ({ onClick }) => {
  const { isLoggedIn, handleLoginRedirect } = usePostAuthLogic();
  const { borrowOffers, loading, error, refreshOffers } = useOfferListLogic();
  const { handleOfferClick, selectedOffer, handleCloseDetails } =
    useOfferLogic();

  const handleClick = () => {
    if (!isLoggedIn) {
      handleLoginRedirect('lend');
    } else {
      localStorage.setItem('ini_role', 'lend');
      onClick();
    }
  };

  return (
    <div className='flex flex-col w-2/3 text-center justify-start ms-auto'>
      {borrowOffers.length > 0 && (
        <div className='relative flex flex-col justify-center items-between bg-highlight rounded-xl '>
          <div className='best-deal'>Best deal</div>
          <div className='flex justify-between items-stretch ps-10'>
            <FaHammer className='text-6xl mt-8' />

            <h1 className='w-full text-2xl mt-8 pe-20'>Lend</h1>
          </div>
          <div className='flex justify-center'>
            <h1 className='w-full text-4xl mb-8'>{`${borrowOffers[0].interest}%`}</h1>
          </div>
          <div className='flex'>
            <Button
              label={`Details`}
              onClick={() => handleOfferClick(borrowOffers[0])}
              className='rounded-xl rounded-t-none  bg-button bg-button-hover w-full py-2 mt-auto'
            />
          </div>
        </div>
      )}
      {isLoggedIn && (
      <div className='flex flex-col justify-center items-between bg-highlight rounded-xl mt-6'>
        <div className='flex justify-between items-center ps-10'>
          <FaHammer className='text-6xl mt-8' />
          <h1 className='w-full text-2xl mt-10 pe-20'>Bid</h1>
        </div>
        <div className='flex w-2/3 mx-auto my-5'>
          <p className='text-center'>
            Leave an order to lend Bitcoin at your rate.
          </p>
        </div>
        <div className='flex'>
          <Button
            label='Details'
            onClick={handleClick}
            className='rounded-xl rounded-t-none  bg-button bg-button-hover w-full py-2 mt-auto'
          />
        </div>
      </div>
      )}

      {loading && <p>Loading offers...</p>}
      {error && <p>{error}</p>}
      {selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={handleCloseDetails}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
    </div>
  );
};

export default BidButton;
