import React from 'react';
import '../../styles/common/qr.scss';

interface QRProps {
  svg: string;
}

const QR: React.FC<QRProps> = ({ svg }) => {
  return (
    <div className='qr-container'>
      <div
        className='qr-content'
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    </div>
  );
};

export default QR;