import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { getToken } from '../api/token';
import { OfferData, OfferResponse } from '../types/offerFace';
import { getOfferById } from '../api/offer';

const useOfferLogic = () => {
  const [selectedOffer, setSelectedOffer] = useState<OfferData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const hasSetSelectedOffer = useRef(false);

  // Handle selectedOffer from localStorage on login
  useEffect(() => {
    if (isLoggedIn && !hasSetSelectedOffer.current) {
      const savedOffer = localStorage.getItem('selectedOffer');
      if (savedOffer) {
        try {
          const offer = JSON.parse(savedOffer);
          setSelectedOffer(offer);
          localStorage.removeItem('selectedOffer');
          hasSetSelectedOffer.current = true;
        } catch (e) {
          console.error('Error parsing selectedOffer from localStorage:', e);
        }
      }
    }
  }, [isLoggedIn]);

  // Add event listener for beforeunload to clear localStorage
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('selectedOffer');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleDetailsClick = async (dlc_id: string) => {
    try {
      const userEmail = localStorage.getItem('userEmail');
      const authToken = getToken();

      if (!authToken || !userEmail) {
        navigate('/loginpage', { state: { from: '/' } });
        return;
      }

      const response: OfferResponse = await getOfferById(dlc_id);
      const offer = response.payload;

      setSelectedOffer(offer);
    // console.log('API Response:', response.payload); // Ellenőrizd az API válaszát

      localStorage.removeItem('selectedOffer');
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(String(error));
      }
    }
  };

  const handleOfferClick = (offer: OfferData) => {
    if (isLoggedIn) {
      handleDetailsClick(offer.dlc_id);
    } else {
      localStorage.setItem('selectedOffer', JSON.stringify(offer));
      handleDetailsClick(offer.dlc_id);
    }
  };

  const handleCloseDetails = () => {
    console.log('Removing selectedOffer from localStorage');
    localStorage.removeItem('selectedOffer');
    setSelectedOffer(null);
    navigate('/');
    hasSetSelectedOffer.current = false; // Reset the ref when closing details
  };

  return {
    selectedOffer,
    setSelectedOffer,
    handleDetailsClick,
    handleOfferClick,
    handleCloseDetails,
    error,
  };
};

export default useOfferLogic;
