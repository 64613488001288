import React from 'react';
import Button from './common/Button'; // Import the Button component
import { OfferDetailsProps } from '../types/offerDetailsFace'; // Import the OfferDetailsProps interface
import { useAcceptOffer } from '../hooks/useAcceptOffer'; // Import the useAcceptOffer hook
import useAuthLogic from '../hooks/useAuthLogic'; // Import the useAuthLogic hook

const OfferDetails: React.FC<OfferDetailsProps> = ({
  offer,
  onClose,
  refreshOffers,
}) => {
  const textColor = offer.ini_role === 'lend' ? 'text-text' : 'text-text';
  const header = offer.ini_role === 'lend' ? 'Lend' : 'Borrow';
  const { acceptOfferWithAuth, loading, error } = useAcceptOffer();
  const { userEmail } = useAuthLogic(); // Get the user's email

  const handleAccept = async () => {
    console.log('Try to accept offer:', offer);

    try {
      const response = await acceptOfferWithAuth({
        dlc_id: offer.dlc_id,
      });
      console.log('Accept offer response:', response);

      alert(response.message);
      onClose();
      refreshOffers(); // Refresh the offer list
    } catch (error: any) {
      if (error.response && error.response.data && error.response.detail) {
        alert(`Error accepting offer: ${error.response.data.detail}`);
      } else if (error instanceof Error && error.message) {
        alert(`Error accepting offer: ${error.message}`);
      } else {
        console.error('Error accepting offer:', error);
      }
    }
  };

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
      <div className='flex flex-col relative bg-white p-16 shadow-lg w-3/4 text-left'>
        <Button
          icon={<span>&times;</span>}
          onClick={onClose}
          className='absolute top-2 right-4 text-text bg-transparent hover:bg-transparent border-none text-3xl hover:bolder focus:bolder hover:text-3xl focus:text-3xl shadow-none'
        />
        <h2 className='text-text bg-secondary p-6 py-2 text-3xl'>
          {header} details
        </h2>
        <div className='grid grid-cols-5 text-center gap-4 px-4 py-2 bg-background'>
          <div className={`${textColor} text-lg`}>DLC ID</div>
          <div className={`${textColor} text-lg`}>Interest</div>
          <div className={`${textColor} text-lg`}>Ear</div>
          <div className={`${textColor} text-lg`}>Role</div>
          <div className={`${textColor} text-lg`}>Due time</div>
        </div>
        <div className='offer_list bg-highlight'>
          <div className='grid grid-cols-5 text-center gap-1 lg:gap-4 border py-1 px-4 shadow-sm transition-transform transform hover:scale-102 hover:shadow-md focus:scale-102 focus:shadow-md bg-highlight-hover border-highlight'>
            <div className={`${textColor}`}>{offer.dlc_id || 'N/A'}</div>
            <div className={`${textColor}`}>{offer.interest || 'N/A'}%</div>
            <div className={`${textColor}`}>
              {offer.interest_ear ? offer.interest_ear.toFixed(2) : 'N/A'}%
            </div>
            <div className={`${textColor}`}>{offer.ini_role || 'N/A'}</div>
            <div className={`${textColor}`}>{offer.duration || 'N/A'} days</div>
          </div>
        </div>

        {userEmail === offer.ini_email && (
          <>
            <p className='text-yellow-500 text-2xl text-center'>
              YOU ARE THE BEST!
            </p>
            <p className='text-yellow-500 text-2xl text-center'>
              This is your own offer!
            </p>
          </>
        )}
        {userEmail !== offer.ini_email && (
          <Button
            label='ACCEPT'
            onClick={handleAccept}
            className='mt-4 w-1/3 lg:w-1/5 bg-button bg-button-hover rounded-sm px-10 ms-auto'
          />
        )}
        {loading && <p>Loading...</p>}
        {error && <p className='text-red-500'>{error}</p>}
      </div>
    </div>
  );
};

export default OfferDetails;
