import React from 'react';

const FeaturesList: React.FC = () => (
  <ul className='my-4 space-y-2 text-left text-text'>
    <li>• No trusted third party</li>
    <li>• Universally accessible</li>
    <li>• Private</li>
    <li>
      • Lender secure smart contracts eliminate settlement and credit risk
    </li>
    <li>• Borrower - No margin call or liquidation if Bitcoin falls</li>
  </ul>
);

export default FeaturesList;
