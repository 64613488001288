import React from 'react';
import { useTopupAddressQR } from '../hooks/useTopupAddress';
import QR from './common/QR';

interface UserTopupAddressQRProps {
  seqNr: number;
}

const UserTopupAddressQR: React.FC<UserTopupAddressQRProps> = ({ seqNr }) => {
  const { qrCode, loading, error } = useTopupAddressQR(seqNr);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return qrCode ? <QR svg={qrCode.payload.qr_code} /> : null;
};

export default UserTopupAddressQR;