import React from 'react';
import Button from './common/Button'; // Import the Button component
import { OfferProps } from '../types/offerFace'; // Import interfaces
import OfferDetails from './OfferDetails'; // Import the OfferDetails component
import useOfferLogic from '../hooks/useOfferLogic'; // Import useOfferLogic hook
import useOfferListLogic from '../hooks/useOfferListLogic'; // Import useOfferListLogic hook

const Offer: React.FC<OfferProps> = ({
  title,
  offers,
  offerClass,
  textColor,
}) => {
  const { selectedOffer, handleOfferClick, handleCloseDetails } =
    useOfferLogic();
  const { refreshOffers } = useOfferListLogic();

  return (
    <div className={`w-3/4 ${offerClass}`}>
      <h2 className={`text-2xl ${textColor} bg-secondary p-4`}>{title}</h2>
      {offers.length > 0 && (
        <div className='grid grid-cols-3 text-center gap-4 px-4 py-2 bg-background'>
          <div className={`${textColor} text-lg`}>Interest</div>
          {/* <div className={`${textColor} font-bold text-lg`}>Duration</div> */}
          <div className={`${textColor} text-lg`}>Ear</div>
          <div className={`${textColor} text-lg`}>View</div>
        </div>
      )}
      <div className='offer_list bg-highlight'>
        {offers.map((offer, index) => (
          <div
            key={index}
            className={`grid grid-cols-3 text-center gap-1 lg:gap-4 border py-1 px-4 shadow-sm transition-transform transform hover:scale-102 hover:shadow-md focus:scale-102 focus:shadow-md  bg-highlight-hover border-highlight`}
          >
            <div className={`${textColor} text-md`}>{offer.interest}%</div>
            {/* <div className={`${textColor} text-lg`}>{offer.duration} days</div> */}
            <div className={`${textColor} text-md`}>
              {offer.interest_ear.toFixed(2)}%
            </div>
            <div>
              <Button
                label='Details'
                onClick={() => handleOfferClick(offer)}
                className='bg-button bg-button-hover rounded-sm text-sm py-0 px-2'
              />
            </div>
          </div>
        ))}
      </div>
      {selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={handleCloseDetails}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
    </div>
  );
};

export default Offer;
