import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { getUserProfile } from '../api/userProfile';
import { UserProfileResponse } from '../types/userFace';

const UserData: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfileResponse | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setUserProfile(profile);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);
  // console.log(userProfile);

  return (
    <>
      <div className='flex-1 flex flex-col space-y-10'>
        {loading && <p className='text-center text-gray-600'>Loading...</p>}
        {error && <p className='text-center text-red-600'>{error}</p>}
        {userProfile && (
          <div className='flex flex-col space-y-4'>
            <div className='flex items-center'>
              <FaUser className='text-blue-500 mr-2' />
              <h2 className='text-2xl'>Hi {userProfile.payload.email}!</h2>
            </div>
            {/* Add more user profile details here */}
          </div>
        )}
      </div>
    </>
  );
};

export default UserData;
