import { getOffers, getUserOffers } from '../api/offers';
import { OfferData } from '../types/offerFace';

export const fetchOffers = async (
  setOffers: (offers: OfferData[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void,
) => {
  setLoading(true);
  setError(null);
  try {
    const response = await getOffers();

    const offersWithDuration = response.payload.map((offer: OfferData) => ({
      ...offer,
      duration: offer.duration || 800, // Ensure duration is set
    }));
    setOffers(offersWithDuration);
  } catch (error) {
    console.error('Failed to fetch offers:', error);
    setError('Failed to fetch offers');
  } finally {
    setLoading(false);
  }
};

export const fetchUserOffers = async (
  setOffers: (offers: OfferData[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void,
) => {
  setLoading(true);
  setError(null);
  try {
    const response = await getUserOffers();

    const offersWithDuration = response.payload.map((offer: OfferData) => ({
      ...offer,
      duration: offer.duration || 800, // Ensure duration is set
    }));
    setOffers(offersWithDuration);
  } catch (error) {
    console.error('Failed to fetch offers:', error);
    setError('Failed to fetch offers');
  } finally {
    setLoading(false);
  }
};