import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { saveToken, getToken, removeToken } from '../api/token'; // Import token functions

interface AuthContextProps {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  saveToken: (token: string) => void;
  getToken: () => string | null;
  removeToken: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  saveToken: () => {},
  getToken: () => null,
  removeToken: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedInState] = useState<boolean>(false);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setIsLoggedInState(true);
    }
  }, []);

  const setIsLoggedIn = (isLoggedIn: boolean) => {
    setIsLoggedInState(isLoggedIn);
    localStorage.setItem('isLoggedIn', isLoggedIn.toString());
    if (isLoggedIn) {
      localStorage.removeItem('selectedOffer'); // Remove selectedOffer when user logs in
      
    }
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, saveToken, getToken, removeToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};