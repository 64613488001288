import React from 'react';
import UserSpecQR from '../components/UserSpecQr';
import UserData from '../components/UserData';
import UserTopupAddressQR from '../components/UserTopupAddressQr';

const MyProfile: React.FC = () => {
  const SeqNr = 0;
  return (
    <>
      <div className='flex flex-col px-20 space-y-10 my-10 mx-20 pt-10 pb-20 bg-highlight'>
        <h1 className='text-4xl text-center text-text'>User Profile</h1>
        <UserData />
        <div className='flex flex-col-1 lg:flex-col-2 gap-x-20'>
          <div className='w-1/2'>
            <h3 className='text-2xl mb-5'>User spec QR:</h3>
            <UserSpecQR />
          </div>
          <div className='w-1/2'>
            <h3 className='text-2xl mb-5'>Topup address QR:</h3>

            <UserTopupAddressQR seqNr={SeqNr} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
