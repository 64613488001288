import React, { useState, useEffect } from 'react';
import OfferPostForm from './OfferPostForm';
import AskButton from './AskButton';
import BidButton from './BidButton';
import usePostAuthLogic from '../hooks/usePostAuthLogic';

const FeatureButtons: React.FC = () => {
  const { isLoggedIn, iniRole } = usePostAuthLogic();
  const [selectedRole, setSelectedRole] = useState<'lend' | 'borrow' | null>(
    iniRole
  );

  useEffect(() => {
    if (iniRole) {
      setSelectedRole(iniRole);
    }
  }, [iniRole]);

  const handleButtonClick = (role: 'lend' | 'borrow') => {
    setSelectedRole(role);
    localStorage.setItem('ini_role', role);
  };

  const handleCloseForm = () => {
    setSelectedRole(null);
    localStorage.removeItem('ini_role');
  };

  return (
    <div className='flex flex-col gap-y-4 my-4 px-3'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
        {isLoggedIn && selectedRole ? (
          <>
            <OfferPostForm
              role={selectedRole}
              onClose={handleCloseForm}
            />
            <BidButton onClick={() => handleButtonClick('lend')} />
            <AskButton onClick={() => handleButtonClick('borrow')} />
          </>
        ) : (
          <>
            <BidButton onClick={() => handleButtonClick('lend')} />
            <AskButton onClick={() => handleButtonClick('borrow')} />
          </>
        )}
      </div>
    </div>
  );
};

export default FeatureButtons;
