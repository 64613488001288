import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import { LoginProps } from '../types/loginFace';
import { login } from '../utils/login';
import { AuthContext } from '../context/AuthContext';
import { getOfferById } from '../api/offer';
import { registerUser } from '../api/user';
import { UserFace } from '../types/userFace';

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, setUserEmail }) => {
  const { saveToken } = useContext(AuthContext);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';

  const handleLoginClick = async () => {
    await login(
      loginEmail,
      loginPassword,
      setIsLoggedIn,
      setUserEmail,
      saveToken,
      navigate,
      setLoading,
      setError
    );

    const selectedOffer = localStorage.getItem('selectedOffer');
    const iniRole = localStorage.getItem('ini_role');
    if (selectedOffer) {
      const offerResponse = await getOfferById(
        JSON.parse(selectedOffer).dlc_id
      );
      localStorage.removeItem('selectedOffer');
      navigate(from, { state: { offer: offerResponse.payload } });
    } else if (iniRole) {
      navigate(from, { state: { ini_role: iniRole } });
    } else {
      navigate(from);
    }
  };

  const handleRegisterClick = async () => {
    const newUser: UserFace = {
      email_address: registerEmail,
      password: registerPassword,
    };

    try {
      setLoading(true);
      await registerUser(newUser);
      setLoading(false);
      navigate('/'); // Navigate to the dashboard or login page after successful registration
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };
  const handleCancelClick = () => {
    navigate('/');
  };

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 z-50 overflow-auto'>
      <div className='flex flex-col lg:flex-row space-y-10 md:space-y-0 md:space-x-10 m-20 p-10 pb-20 bg-highlight'>
        <div className='flex-1 flex flex-col space-y-10'>
          <h1 className='text-4xl text-center mt-8 text-text'>Welcome back</h1>
          <p className='text-md w-2/3 text-center text-text mx-auto'>
            If you want to browse the offers in detail, make a deal or make a
            bid, register here.
          </p>
          <form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col space-y-2 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto'
          >
            <Input
              type='email'
              name='loginEmail'
              value={loginEmail}
              placeholder='Email address'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLoginEmail(e.target.value)
              }
              required
              autoComplete='email'
            />
            <Input
              type='password'
              name='loginPassword'
              value={loginPassword}
              placeholder='Password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLoginPassword(e.target.value)
              }
              required
              autoComplete='password'
            />
            <div className='flex flex-row justify-between space-x-4'>
              <div className='w-full'>
                <Button
                  label='Sign in'
                  onClick={handleLoginClick}
                  disabled={loading}
                  className='bg-button bg-button-hover px-10'
                />
              </div>
              <div className='w-full'>
                <Button
                  label='Cancel'
                  onClick={handleCancelClick}
                  className='bg-button bg-button-hover px-10'
                />
              </div>
            </div>
            {loading && (
              <p className='text-center text-gray-600'>Processing request...</p>
            )}
            {error && <p className='text-center text-red-600'>{error}</p>}
          </form>
        </div>
        <div className='flex-1 flex flex-col space-y-10'>
          <h1 className='text-4xl text-center mt-8 text-text'>
            Create Account
          </h1>
          <p className='text-md w-2/3 text-center text-text mx-auto'>
            If you want to browse the offers in detail, make a deal or make a
            bid, register here.
          </p>
          <form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col space-y-2 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto'
          >
            <Input
              type='email'
              name='registerEmail'
              value={registerEmail}
              placeholder='Email address'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRegisterEmail(e.target.value)
              }
              required
              autoComplete='email'
            />
            <Input
              type='password'
              name='registerPassword'
              value={registerPassword}
              placeholder='Password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRegisterPassword(e.target.value)
              }
              required
              autoComplete='password'
            />
            <div className='flex flex-row justify-between space-x-4'>
              <div className='w-full'>
                <Button
                  label='Register'
                  onClick={handleRegisterClick}
                  disabled={loading}
                  className='bg-button bg-button-hover px-10'
                />
              </div>
              <div className='w-full'>
                <Button
                  label='Cancel'
                  onClick={handleCancelClick}
                  className='bg-button bg-button-hover px-10'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
