import React from 'react';
// import PriceList from '../components/PriceList';
import OffersList from '../components/OffersList';
import UserDlcsList from '../components/UserDlcsList';
import OfferDetails from '../components/OfferDetails';
import useDashboardLogic from '../hooks/useDashboardLogic';
import FeatureButtons from '../components/FeatureButtons';
import useOfferListLogic from '../hooks/useOfferListLogic';
import ExplainerVideo from '../components/ExplainerVideo';
import FeaturesList from '../components/FeaturesList';
import Footer from '../components/Footer';

interface DashboardProps {
  isLoggedIn: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ isLoggedIn }) => {
  const { selectedOffer, setSelectedOffer } = useDashboardLogic(isLoggedIn);
  const { refreshOffers } = useOfferListLogic();

  return (
    <div className='mt-8'>
      <h1 className='text-2xl text-center my-10'>
        Deal at our live Bitcoin borrowing and lending rates or leave an order.
      </h1>
      <FeatureButtons />
      {isLoggedIn ? (
        <>
          <OffersList />
          <UserDlcsList />
        </>
      ) : (
        <>{/* <OffersList /> */}</>
      )}

      {/* <PriceList /> */}

      {isLoggedIn && selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
            localStorage.removeItem('selectedOffer');
          }}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
      {isLoggedIn ? (
        <></>
      ) : (
        <>
          <div className='px-6'>
            <div className='flex flex-col md:flex-row md:space-x-8 w-full px-4 items-center justify-center'>
              <div className='w-full md:w-1/2 flex justify-center md:justify-end'>
                <ExplainerVideo />
              </div>
              <div className='w-full md:w-1/2'>
                <FeaturesList />
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Dashboard;
