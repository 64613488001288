import { useState, useEffect } from 'react';
import { getUserQR } from '../api/qr';
import { QRCodeUserResponse } from '../types/common/qrFace';

export const useUserQR = () => {
  const [qrCode, setQRCode] = useState<QRCodeUserResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const response = await getUserQR();
        setQRCode(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchQRCode();
  }, []);

  return { qrCode, loading, error };
};