import React from 'react';
import { useUserQR } from '../hooks/useUserQR';
import QR from './common/QR';

const UserSpecQR: React.FC = () => {
  const { qrCode, loading, error } = useUserQR();


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return qrCode ? <QR svg={qrCode.payload.qr_code} /> : null;
};

export default UserSpecQR;