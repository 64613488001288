import React from 'react';
import Offer from './Offer';
import useOfferListLogic from '../hooks/useOfferListLogic';
// import Button from './common/Button';
// import { FaSyncAlt } from 'react-icons/fa';

const OffersList: React.FC = () => {
  // const { borrowOffers, lendOffers, loading, error, refreshOffers } =
  const { borrowOffers, lendOffers, loading, error } = useOfferListLogic();

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='py-4 px-3'>
      {/* <div className='text-right'>
        <Button
          icon={<FaSyncAlt />}
          onClick={refreshOffers}
        />
      </div> */}

      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
        <Offer
          title='Borrow'
          offers={borrowOffers}
          offerClass='justify-self-center md:justify-self-end'
          textColor='text-text'
        />
        <Offer
          title='Lend'
          offers={lendOffers}
          offerClass='justify-self-center md:justify-self-start mt-8 md:mt-0'
          textColor='text-text'
        />
      </div>
    </div>
  );
};

export default OffersList;
