import { useState, useEffect } from 'react';
import { fetchOffers, fetchUserOffers } from '../utils/offerList'; // Import fetchOffers and fetchUserOffers from offerList.ts
import { OfferData } from '../types/offerFace';
import useAuthLogic from './useAuthLogic'; // Import useAuthLogic

const NR_BEST_OFFERS = 4; // Set the number of best offers

const useOfferListLogic = () => {
  const [offers, setOffers] = useState<OfferData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  const {isLoggedIn } = useAuthLogic(); // Get the user's login status

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');

    if (isLoggedIn && userEmail) {
      fetchUserOffers(setOffers, setLoading, setError); // Fetch user offers if logged in
    } else if (!isLoggedIn && !userEmail) {
      fetchOffers(setOffers, setLoading, setError); // Fetch offers if not logged in
    }
  }, [isLoggedIn]);

  const borrowOffers = offers
    .filter((offer) => offer.ini_role === 'borrow')
    .map((offer) => ({
      ...offer,
    }))
    .sort((a, b) => b.interest - a.interest)
    .slice(0, NR_BEST_OFFERS);

  const lendOffers = offers
    .filter((offer) => offer.ini_role === 'lend')
    .map((offer) => ({
      ...offer,
    }))
    .sort((a, b) => a.interest - b.interest)
    .slice(0, NR_BEST_OFFERS);

  const refreshOffers = () => {
    if (isLoggedIn) {
      fetchUserOffers(setOffers, setLoading, setError); // Fetch user offers if logged in
    } else {
      fetchOffers(setOffers, setLoading, setError); // Fetch offers if not logged in
    }
  };

  return {
    borrowOffers,
    lendOffers,
    loading,
    error,
    refreshOffers, // Export refreshOffers instead of fetchOffersData
  };
};

export default useOfferListLogic;
