import axios from 'axios';
import { API_URL } from '../config';
import { UserFace } from '../types/userFace';

export const registerUser = async (user: UserFace): Promise<void> => {
  try {
    await axios.post(`${API_URL}/app/auth/new-user`, user);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status === 400) {
        throw new Error(`400 Bad Request: ${error.response.data.message}`);
      } else {
        throw new Error(
          '500 Internal Server Error: Operation failed due to unforeseen circumstances.'
        );
      }
    } else {
      throw new Error('Unexpected error occurred.');
    }
  }
};
