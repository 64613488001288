import React from 'react';

const Footer: React.FC = () => (
  <footer className='bg-accent mt-auto py-4 w-full flex flex-col items-center'>
    <div className='flex space-x-4 my-2'>
      {/* Placeholder for social media icons */}
      <div className='bg-gray-500 w-8 h-8 rounded-full flex items-center justify-center'>
        FB
      </div>
      <div className='bg-gray-500 w-8 h-8 rounded-full flex items-center justify-center'>
        IG
      </div>
      <div className='bg-gray-500 w-8 h-8 rounded-full flex items-center justify-center'>
        LI
      </div>
    </div>
    <div className='space-x-4 text-sm text-gray-500'>
      <a href='#faq'>FAQ</a>
      <a href='#security'>Security</a>
      <a href='#contact'>Contact</a>
      <a href='#privacy-policy'>Privacy Policy</a>
    </div>
  </footer>
);

export default Footer;
